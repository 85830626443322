import React from 'react'

import Layout from 'components/layout'
import SEO from 'components/seo'
import Terms from 'components/Terms'

const Privacy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <Terms name="PRIVACY_POLICY" split />
    </Layout>
  )
}

export default Privacy
